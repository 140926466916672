import React, { Fragment } from "react"
import Layout from "../components/Layout"
import LayoutSimple from "../components/layoutSimple"
import SEO from "../components/seo"
import SocialShareForm from "../components/SocialShareForm/SocialShareForm"

const SignUnPage = () => {
  React.useEffect(() => {
    document.title = "Spread the word | voyayge"
  }, [])

  return (
    <Fragment>
      {/* <SignUpForm type={"traveler"} /> */}
      <SocialShareForm />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO
      pageTitle="Spread Your Love for Travel"
      description="If you like what we are doing, then spread the word!"
    />
    <SignUnPage />
  </Layout>
)
